import { gql } from '@apollo/client';

const GET_BUNDLE = gql`
  query Bundle($external_id: String!) {
    bundle(external_id: $external_id) {
      name
      originator_email
      cobundlers {
        email
      }
      products {
        products
      }
      total_price_cents
      total_savings_cents
      stripe_price_id
    }
  }
`;

export default GET_BUNDLE;
