import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { UserContext } from '../../App';
import Confirmation from './Confirmation';
import Stripe from './Stripe';
import Bundles from './Bundles';

const Home = () => {
  const [user] = useContext(UserContext);
  if (!user.access_token) {
    return <Redirect to="login" />;
  }

  if (!user.creator) {
    return <Redirect to="consumer" />;
  }

  return user.confirmed ? (
    user.connected_for_payment ? (
      <Bundles />
    ) : (
      <Stripe />
    )
  ) : (
    <Confirmation />
  );
};

export default Home;
