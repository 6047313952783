import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import CurrencyInput from './CurrencyInput';

const PricePage = () => {
  return (
    <>
      <Box textAlign="left">
        <Heading>What's your Monthly Bundle price?</Heading>
      </Box>
      <Box my={4} textAlign="left">
        <Text color="gray.400">
          Include{' '}
          <Text as="span" fontWeight="700">
            your price
          </Text>{' '}
          for the bundle -{' '}
          <Text as="span" fontWeight="700">
            each bundle participant lists their price separately
          </Text>{' '}
          (you'll see these listed with the total in your dashboard).
        </Text>
      </Box>
      <CurrencyInput
        variant="flushed"
        placeholder="Your price for this Bundle: $0.00"
        name="price"
      />
      <CurrencyInput
        variant="flushed"
        placeholder="Savings from your standard price (optional): $0.00"
        name="savings"
      />
    </>
  );
};

export default PricePage;
