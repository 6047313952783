import React from 'react';
import {
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
} from '@chakra-ui/react';
import { useField } from 'formik';

const CurrencyInput = ({ ...props }) => {
  const [field, meta] = useField(props);
  return (
    <FormControl isInvalid={meta.touched && meta.error}>
      <InputGroup>
        <InputLeftElement pointerEvents="none" fontSize="1.2em" children="$" />
        <Input type="number" {...field} {...props} />
      </InputGroup>
      {meta.touched && meta.error ? (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      ) : null}
    </FormControl>
  );
};

export default CurrencyInput;
