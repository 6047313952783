import { gql } from '@apollo/client';

const RESUME_CONNECT_ONBOARDING = gql`
  mutation {
    resumeConnectOnboarding {
      url
    }
  }
`;

export default RESUME_CONNECT_ONBOARDING;
