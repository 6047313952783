import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

import { UserContext } from '../../App';
import Logo from './Logo';
import Hamburger from './Hamburger';
import MenuLinks from './MenuLinks';
import showSidebar from '../../utils/showSidebar';
import SidebarLinks from './SidebarLinks';

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align={[
        props.isOpen ? 'flex-start' : 'flex-end',
        props.isOpen ? 'flex-start' : 'flex-end',
        'flex-end',
      ]}
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={8}
      p={4}
      direction={props.isOpen ? 'column' : 'row'}
    >
      {children}
    </Flex>
  );
};

const Header = props => {
  const [user] = useContext(UserContext);
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer isOpen={isOpen}>
      <Link to="/">
        <Logo display={['flex', 'flex', showSidebar(user) ? 'none' : 'flex']} />
      </Link>
      <SidebarLinks
        display={[
          isOpen && showSidebar(user) ? 'flex' : 'none',
          isOpen && showSidebar(user) ? 'flex' : 'none',
          'none',
        ]}
      />
      <MenuLinks isOpen={isOpen} />
      <Hamburger toggle={toggle} isOpen={isOpen} />
    </NavBarContainer>
  );
};

export default Header;
