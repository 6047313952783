import { gql } from '@apollo/client';

const REGISTER = gql`
  mutation Register($fields: RegisterInput!) {
    register(fields: $fields) {
      access_token
      email
      confirmed
      connected_for_payment
      creator
    }
  }
`;

export default REGISTER;
