import React from 'react';
import { Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

const MenuItem = ({ children, isLast, to = '/', ...rest }) => {
  let location = useLocation();
  return (
    <Link to={{ pathname: to, state: { from: location.pathname } }}>
      <Text display="block" {...rest}>
        {children}
      </Text>
    </Link>
  );
};

export default MenuItem;
