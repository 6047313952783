import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../App';
import REGISTER from '../../graphql/mutations/register';
import ErrorMessage from '../common/ErrorMessage';
import Terms from '../common/Terms';

const Register = () => {
  const [user, setUser] = useContext(UserContext);
  let history = useHistory();

  useEffect(() => {
    if (user.access_token) {
      history.push('/');
    }
  }, [history, user.access_token]);

  const [register, { loading, error }] = useMutation(REGISTER, {
    onCompleted({ register }) {
      if (register.access_token) {
        setUser({
          access_token: register.access_token,
          email: register.email,
          confirmed: register.confirmed,
          connected_for_payment: register.connected_for_payment,
          creator: register.creator,
        });
        history.push('/');
      } else {
        console.error('No access token returned upon registering.');
      }
    },
    onError() {},
  });
  const [show, setShow] = useState(false);

  const handleToggleClick = () => setShow(!show);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email')
        .required('The email is required'),
      password: Yup.string()
        .min(10, 'Must be at least ten character long')
        .max(64, 'Must be at most 64 characters long')
        .required('The password is required'),
    }),
    onSubmit: values => {
      register({
        variables: {
          fields: {
            email: values.email,
            password: values.password,
            creator: true,
          },
        },
      });
    },
  });

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Box mx={5}>
        <Box textAlign="left">
          <Heading>Get Started</Heading>
        </Box>
        <Box my={4} textAlign="left">
          <Text color="gray.400">
            Welcome to BundlePay! Let's get started by creating an account.
          </Text>
        </Box>
        <Box my={4} textAlign="left">
          <form onSubmit={formik.handleSubmit}>
            {error && <ErrorMessage error={error} />}
            <FormControl
              isInvalid={formik.errors.email && formik.touched.email}
            >
              <Input
                variant="flushed"
                placeholder="Email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl
              mt={6}
              isInvalid={formik.errors.password && formik.touched.password}
            >
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? 'text' : 'password'}
                  variant="flushed"
                  placeholder="Password"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    variant="outline"
                    h="1.75rem"
                    size="sm"
                    onClick={handleToggleClick}
                  >
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>
            <Box textAlign="center">
              <Button mt={6} type="submit" isLoading={loading ? true : false}>
                Continue
              </Button>
            </Box>
          </form>
        </Box>
        <Box my={6} textAlign="center">
          <Terms />
        </Box>
      </Box>
    </Flex>
  );
};

export default Register;
