import React, { useState } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';

import RESUME_CONNECT_ONBOARDING from '../../graphql/mutations/resumeConnectOnboarding';
import StripeButton from '../common/StripeButton';

const Refresh = props => {
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState('');
  const [resumeConnectOnboarding] = useMutation(RESUME_CONNECT_ONBOARDING, {
    onCompleted({ resumeConnectOnboarding }) {
      if (resumeConnectOnboarding.url) {
        setRedirecting('Redirecting to Stripe...');
        console.log(
          `Redirecting to Stripe Connect onboarding: ${resumeConnectOnboarding.url}`
        );
        window.location.replace(resumeConnectOnboarding.url);
      } else {
        console.error('Resuming Stripe Connect onboarding failed!');
        setLoading(false);
      }
    },
    onError() {
      setLoading(false);
    },
  });

  const content = props.message
    ? props.message
    : 'The Stripe Connect onboarding link either expired or is invalid. Please click the button below to try again.';

  return (
    <Flex width="full" align="center" justifyContent="center" mt={24}>
      <Box maxWidth="650px" mx={5}>
        <Box textAlign="left">
          <Heading>You're almost there</Heading>
        </Box>
        <Text my={4} color="gray.400">
          {content}
        </Text>
        <Box my={6} textAlign="center">
          <StripeButton
            text="Resume onboarding in"
            clickHandler={() => {
              resumeConnectOnboarding();
              setLoading(true);
            }}
            isResumeType={true}
            loading={loading}
            disabled={loading}
          >
            Resume Stripe onboarding
          </StripeButton>
        </Box>
        <Text textAlign="center" color="gray.400">
          {redirecting}
        </Text>
      </Box>
    </Flex>
  );
};

export default Refresh;
