import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { EmailIcon } from '@chakra-ui/icons';

import { UserContext } from '../../App';
import ErrorMessage from './ErrorMessage';
import SEND_PASSWORD_RESET_EMAIL from '../../graphql/mutations/sendPasswordResetEmail.js';

const ForgotPassword = () => {
  const [user] = useContext(UserContext);
  let history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState('Send Password Reset Email');

  useEffect(() => {
    if (user.access_token) {
      history.push('/');
    }
  }, [history, user.access_token]);

  const [sendPasswordResetEmail, { loading, error }] = useMutation(
    SEND_PASSWORD_RESET_EMAIL,
    {
      onCompleted() {
        setButtonDisabled(true);
        setButtonText('Email sent!');
      },
      onError() {},
    }
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email')
        .required('The email is required'),
    }),
    onSubmit: values => {
      sendPasswordResetEmail({ variables: { email: values.email } });
    },
  });

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Box mx={5} width="90%" maxWidth="450px">
        <Box textAlign="center">
          <Heading>Reset your password</Heading>
        </Box>
        <Box mt={6} textAlign="left">
          <Text color="gray.400">
            Please enter your email address of your account and we will send you
            a password reset link.
          </Text>
        </Box>
        <Box mt={6} textAlign="left">
          <form onSubmit={formik.handleSubmit}>
            {error && <ErrorMessage error={error} />}
            <FormControl
              isInvalid={formik.errors.email && formik.touched.email}
            >
              <Input
                variant="flushed"
                placeholder="Email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
            </FormControl>
            <Box textAlign="center">
              <Button
                leftIcon={<EmailIcon />}
                mt={6}
                type="submit"
                isLoading={loading ? true : false}
                disabled={buttonDisabled}
              >
                {buttonText}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default ForgotPassword;
