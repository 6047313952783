import React, { useContext } from 'react';
import { Button } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';

import { UserContext } from '../../App';
import LOGOUT from '../../graphql/mutations/logout';

const Logout = () => {
  const [, setUser] = useContext(UserContext);
  const [logout, { loading }] = useMutation(LOGOUT, {
    onCompleted() {
      setUser({ access_token: '' });
    },
    onError() {
      console.error('Logout error!');
      // TODO: feedback to the user
    },
  });

  return (
    <Button
      variant="outline"
      onClick={() => logout()}
      isLoading={loading ? true : false}
    >
      Sign Out
    </Button>
  );
};

export default Logout;
