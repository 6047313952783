import React, { useState } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';

import CONNECT_ONBOARDING from '../../graphql/mutations/connectOnboarding';
import StripeButton from '../common/StripeButton';

const Stripe = () => {
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState('');
  const [connectOnboarding] = useMutation(CONNECT_ONBOARDING, {
    onCompleted({ connectOnboarding }) {
      setRedirecting('Redirecting to Stripe...');
      if (connectOnboarding.url) {
        console.log(
          `Redirecting to Stripe Connect onboarding: ${connectOnboarding.url}`
        );
        window.location.replace(connectOnboarding.url);
      } else {
        console.error('Stripe Connect onboarding failed!');
        setLoading(false);
      }
    },
    onError() {
      setLoading(false);
    },
  });
  return (
    <Flex width="full" align="center" justifyContent="center" mt={24}>
      <Box mx={5} maxWidth="600px">
        <Box textAlign="left">
          <Heading>Great. Now let's get you connected to Stripe.</Heading>
        </Box>
        <Box my={4} textAlign="left">
          <Text color="gray.400">
            We use Stripe Connect to securely link your bank account and ensure
            that you control your financial data.
          </Text>
        </Box>
        <Box my={6} textAlign="center">
          <StripeButton
            mt={6}
            text="Connect to"
            clickHandler={() => {
              connectOnboarding();
              setLoading(true);
            }}
            isResumeType={false}
            loading={loading}
            disabled={loading}
          />
        </Box>
        <Text textAlign="center">{redirecting}</Text>
      </Box>
    </Flex>
  );
};

export default Stripe;
