import React, { useContext, useEffect } from 'react';
import { Flex, VStack, useColorMode } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { UserContext } from '../../App';
import Header from './Header';
import Sidebar from './Sidebar';
import showSidebar from '../../utils/showSidebar';

const Layout = ({ children }) => {
  const [user] = useContext(UserContext);

  let currentPath = useLocation();
  const { colorMode, toggleColorMode } = useColorMode();

  // change to dark theme for the consumer pages; the path matching should be improved later or namespace it
  useEffect(() => {
    if (
      currentPath.pathname.startsWith('/bundle/') ||
      currentPath.pathname.startsWith('/subscribe/') ||
      currentPath.pathname.startsWith('/consumer')
    ) {
      if (colorMode === 'light') toggleColorMode();
    } else {
      if (colorMode === 'dark') toggleColorMode();
    }
  }, [colorMode, toggleColorMode, currentPath.pathname]);

  return (
    <Flex height="full">
      <Sidebar
        width="204px"
        display={['none', 'none', showSidebar(user) ? 'block' : 'none']}
      />
      <VStack flex="1" direction="column">
        <Header />
        {children}
      </VStack>
    </Flex>
  );
};

export default Layout;
