import React from 'react';
import { FormErrorMessage, Input, FormControl } from '@chakra-ui/react';
import { useField } from 'formik';

const CustomTextInput = props => {
  const [field, meta] = useField(props);
  return (
    <FormControl isInvalid={meta.touched && meta.error}>
      <Input {...field} {...props} />
      {meta.touched && meta.error && (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default CustomTextInput;
