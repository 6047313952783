import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { GrDocumentCsv } from 'react-icons/gr';

import GET_SUBSCRIPTIONS_FOR_CREATOR from '../../graphql/queries/subscribers';
import Spinner from '../common/Spinner';

const Subscribers = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [csvData, setCsvData] = useState();

  // useLazyQuery + useEffect instead of just useQuery because of bs error: https://github.com/apollographql/apollo-client/issues/6209
  const [myQueryExecutor, { error, data }] = useLazyQuery(
    GET_SUBSCRIPTIONS_FOR_CREATOR,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted() {
        setCsvData(
          data.subscriptionsForCreator.map(sub => ({
            stripe_customer_email: sub.consumer_email,
            stripe_customer_id: sub.stripe_customer_id,
            stripe_subscription_id: sub.stripe_subscription_id,
            bundle_name: sub.bundle_name,
            bundle_external_id: sub.bundle_external_id,

            // this boolean-to-string convert due to this bug https://github.com/react-csv/react-csv/issues/145 which was
            // fixed with https://github.com/react-csv/react-csv/pull/193 but no new versions of react-csv (after 2.0.3) since this fix.
            active: sub.active ? 'true' : 'false',
          }))
        );
        setLoading(false);
      },
    }
  );

  // execute query on component mount
  useEffect(() => {
    myQueryExecutor();
  }, [myQueryExecutor]);

  if (error) {
    console.error(error);
  }

  const subsTable = (
    <Table my={6} size="sm">
      <Thead>
        <Tr>
          <Th>Email</Th>
          <Th>Bundle</Th>
          <Th>Sub Status</Th>
        </Tr>
      </Thead>
      <Tbody color="gray.400">
        {data
          ? data.subscriptionsForCreator.map(sub => (
              <Tr key={sub._id}>
                <Td>{sub.consumer_email}</Td>
                <Td>{sub.bundle_name}</Td>
                <Td>{sub.active ? 'Active' : 'Canceled'}</Td>
              </Tr>
            ))
          : null}
      </Tbody>
    </Table>
  );

  const dashboard = (
    <Flex width="full" align="center" justifyContent="center" mt={24}>
      <Box mx={5} maxWidth="1200px">
        <Box textAlign="left">
          <Heading as="h2" size="xl">
            Your Subscribers
          </Heading>
        </Box>
        <Box my={4} textAlign="left">
          <Text color="gray.400">
            All of your subscribers are listed below, including the products and
            bundle's they've subscribed to, their status, and whether you've
            given them access. You can download this sheet at anytime.
          </Text>
        </Box>
        {csvData && csvData.length > 0 ? (
          <Box mb={-3} align="right">
            <CSVLink data={csvData} filename="subscribers.csv">
              <GrDocumentCsv />
            </CSVLink>
          </Box>
        ) : null}
        <Box mb={8} textAlign="left">
          {subsTable}
        </Box>
        <Box my={6} textAlign="center">
          <Button mt={6} onClick={() => history.push('/create')}>
            + Bundle
          </Button>
        </Box>
      </Box>
    </Flex>
  );

  const noSubscribers = (
    <div>There are no subscribers to your bundles yet.</div>
  );

  return loading ? (
    <Spinner />
  ) : data.subscriptionsForCreator.length ? (
    dashboard
  ) : (
    noSubscribers
  );
};

export default Subscribers;
