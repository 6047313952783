import { gql } from '@apollo/client';

const GET_SUBSCRIPTIONS_FOR_CREATOR = gql`
  query {
    subscriptionsForCreator {
      _id
      consumer_email
      stripe_customer_id
      stripe_subscription_id
      bundle_name
      bundle_external_id
      active
    }
  }
`;

export default GET_SUBSCRIPTIONS_FOR_CREATOR;
