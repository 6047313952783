import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';

import STRIPE_CHECKOUT_SESSION from '../../graphql/mutations/stripeCheckoutSession';
import Spinner from '../common/Spinner';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payment = () => {
  const { bundlecode } = useParams();
  const [stripeCheckoutSession, { loading }] = useMutation(
    STRIPE_CHECKOUT_SESSION,
    {
      async onCompleted(data) {
        console.log(
          `Stripe Checkout mutation returned successfully with Stripe Checkout session ID: ${data.stripeCheckoutSession}`
        );

        // Get Stripe.js instance
        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({
          sessionId: data.stripeCheckoutSession,
        });

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          console.error(result.error);
        }
      },
      onError() {
        console.error('Stripe Checkout mutation returned an error!');
      },
    }
  );

  useEffect(() => {
    stripeCheckoutSession({ variables: { bundleCode: bundlecode } });
  }, [bundlecode, stripeCheckoutSession]);

  return (
    <>{loading ? <Spinner /> : <div>Redirecting to Stripe Checkout...</div>}</>
  );
};

export default Payment;
