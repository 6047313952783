import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const colors = {
  // the closest palette https://themera.vercel.app/ would generate
  // from the #F4F4F4 button color in Figma for consumer pages
  gray: {
    50: '#F2F2F2',
    100: '#DBDBDB',
    200: '#C4C4C4',
    300: '#ADADAD',
    400: '#969696',
    500: '#808080',
    600: '#666666',
    700: '#4D4D4D',
    800: '#071534', // #333333 from pallete, #1a202c from Chakra's docs
    900: '#1A1A1A',
  },
  // from the #0040DD button color in Figma for creator pages
  blue: {
    50: '#E5EDFF',
    100: '#B8CCFF',
    200: '#8AACFF',
    300: '#5C8BFF',
    400: '#2E6AFF',
    500: '#004AFF',
    600: '#003BCC',
    700: '#002C99',
    800: '#001E66',
    900: '#000F33',
  },
};

const components = {
  Button: {
    variants: {
      solid: props => ({
        bg: mode(colors.blue['500'], colors.gray['50'])(props),
        color: mode('white', 'black')(props),
        _hover: {
          bg: mode(colors.blue['600'], colors.gray['100'])(props),
        },
        _active: {
          bg: mode(colors.blue['700'], colors.gray['200'])(props),
          borderColor: mode(colors.blue['800'], colors.gray['300'])(props),
        },
      }),
    },
  },
  LogoLeftWord: {
    baseStyle: ({ colorMode }) => ({
      color: colorMode === 'dark' ? 'white' : '#071534',
    }),
  },
  LogoRightWord: {
    baseStyle: ({ colorMode }) => ({
      color: colorMode === 'dark' ? '#D4D1D1' : '#8E8E93',
    }),
  },
  Spinner: {
    variants: {
      'bundle-page-load': props => ({
        color: mode(colors.blue['500'], colors.gray['100'])(props),
      }),
    },
  },
};

const fonts = {
  body: 'Helvetica, Verdana, Arial, sans-serif',
  heading: 'Helvetica, Verdana, Arial, sans-serif',
};

const bundlePayTheme = extendTheme({
  colors,
  components,
  fonts,
  config,
});

export default bundlePayTheme;
