import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { UserContext } from '../../App';
import STRIPE_ACCOUNT from '../../graphql/mutations/stripeAccount';
import UPDATE_USER from '../../graphql/mutations/updateUser';
import Refresh from './Refresh';
import Spinner from '../common/Spinner';

const Connected = () => {
  let history = useHistory();

  // not using the loading prop from the mutation since the render runs before the useEffect and we need to show
  // the loading animation from the beginning since the API will tell us the type of content we need to display here
  const [loading, setLoading] = useState(true);

  const [pendingInfoInStripe, setPendingInfoInStripe] = useState(true);

  const [user, setUser] = useContext(UserContext);
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted() {
      setUser({
        ...user,
        connected_for_payment: true,
      });
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  // check that onboarding is actually fully complete in Stripe as
  // per https://stripe.com/docs/connect/express-accounts#handle-users-not-completed-onboarding
  const [stripeAccount] = useMutation(STRIPE_ACCOUNT, {
    onCompleted({ stripeAccount }) {
      if (stripeAccount.details_submitted) {
        setPendingInfoInStripe(false);
        updateUser({
          variables: {
            fields: { connected_for_payment: true },
          },
        });
      } else {
        console.debug(
          'User still needs to provide extra information in Stripe!'
        );
        setLoading(false);
      }
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    stripeAccount();
  }, [stripeAccount]);

  const connectedContent = (
    <VStack mt={24} mx={5}>
      <Box textAlign="center">
        <Heading>You're Connected!</Heading>
      </Box>
      <Box my={4} textAlign="center">
        <Text color="gray.400">Now let's get your first bundle created :)</Text>
      </Box>
      <Button onClick={() => history.push('/')}>Continue</Button>
    </VStack>
  );

  // more details here: https://stripe.com/docs/connect/express-accounts#return_url
  // "Stripe issues a redirect to this URL when the user completes the Connect Onboarding flow. This doesn’t mean that all information has been collected or that there are no outstanding requirements on the account. This only means the flow was entered and exited properly."
  const pendingInfoContent = (
    <Refresh message="There is still pending information to be collected by Stripe before your account is fully onboarded. Please follow the button below to resume the Stripe onboarding." />
  );

  return loading ? (
    <Spinner />
  ) : pendingInfoInStripe ? (
    <>{pendingInfoContent}</>
  ) : (
    <>{connectedContent}</>
  );
};

export default Connected;
