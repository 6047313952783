import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Box, Button, Container, VStack } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';

import RESEND_VERIFICATION_EMAIL from '../../graphql/mutations/resendVerificationEmail';

const Confirmation = () => {
  const [buttonText, setButtonText] = useState('Resend verification email');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [resendVerificationEmail] = useMutation(RESEND_VERIFICATION_EMAIL, {
    onCompleted() {
      setButtonDisabled(true);
      setButtonText('Email sent!');
    },
    onError() {
      console.error('Error resending the verification email!');
    },
  });

  return (
    <VStack spacing={4}>
      <Container maxW="4xl">
        <Box>
          We have sent you an email in order to confirm your email address is
          active. Please check your email and follow the verification link from
          it.
        </Box>
        <Box mt={4}>
          If you have not received an email from us, click the button below to
          have it resent.
        </Box>
      </Container>
      <Button
        leftIcon={<EmailIcon />}
        variant="outline"
        onClick={resendVerificationEmail}
        disabled={buttonDisabled}
      >
        {buttonText}
      </Button>
    </VStack>
  );
};

export default Confirmation;
