import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  VStack,
  useColorMode,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';

import { UserContext } from '../../App';
import CREATE_BUNDLE from '../../graphql/mutations/createBundle';
import CustomTextInput from './bundleForm/CustomTextInput';
import ProductsPage from './bundleForm/ProductsPage';
import PricePage from './bundleForm/PricePage';

const EmailsPage = props => {
  const { colorMode } = useColorMode();
  const addEmailInput = () => {
    const emails = [...props.values.emails, ''];
    props.setValues({ ...props.values, emails });
  };
  return (
    <>
      <Box textAlign="left">
        <Heading>Next, list your co-bundlers.</Heading>
      </Box>
      <Box my={4} textAlign="left">
        <Text color="gray.400">
          Provide the emails of all bundlees. Only one person needs to do this
          step for everyone in the bundle.
        </Text>
      </Box>
      <Input
        variant="filled"
        isDisabled={true}
        isReadOnly={true}
        value={props.user.email}
        color={colorMode === 'dark' ? 'white' : 'black'}
        pl={2}
      />
      {props.values.emails.map((_, i) => {
        return (
          <CustomTextInput
            variant="flushed"
            placeholder="Email"
            name={`emails.${i}`}
            key={i}
          />
        );
      })}
      <Box mt={3} textAlign="center">
        <Button
          color="gray.400"
          fontWeight={400}
          variant="link"
          _hover={{ textDecoration: 'none' }}
          onClick={addEmailInput}
        >
          + add more bundlers.
        </Button>
      </Box>
    </>
  );
};

const BundleConfirmationPage = props => {
  let history = useHistory();
  return (
    <>
      <VStack maxWidth="700px" mt={24}>
        <Box px={5} textAlign="left">
          <Heading>
            Bundle created!{' '}
            {props.isCobundling
              ? 'Awaiting confirmation from your collaborators.'
              : 'Your bundle is now live and ready to be shared.'}
          </Heading>
        </Box>
        <Box p={5} textAlign="left">
          <Text color="gray.400">
            {props.isCobundling
              ? 'We’ve emailed all of your co-bundlers, and, once they confirm their pricing + participation we will provide your Bundle link :)'
              : 'You can access the link in your dashboard now, see your earnings in Stripe, and manage your subscribers. Click the link below to get started.'}
          </Text>
        </Box>
        <Button onClick={() => history.push('/')}>Continue</Button>
      </VStack>
    </>
  );
};

const CreateBundle = () => {
  const [user] = useContext(UserContext);
  const [isCobundling, setIsCobundling] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [createBundle, { loading }] = useMutation(CREATE_BUNDLE, {
    onCompleted() {
      console.log('Bundle created successfully!');
      setShowConfirmation(true);
    },
    onError() {},
  });

  const namePage = (
    <>
      <Box textAlign="left">
        <Heading>First, let's name your Bundle.</Heading>
      </Box>
      <Box my={4} textAlign="left">
        <Text color="gray.400">
          Provide the customer facing Bundle name. Only one person needs to do
          this step for everyone in the Bundle.
        </Text>
      </Box>
      <CustomTextInput variant="flushed" placeholder="Name" name="name" />
    </>
  );

  return showConfirmation ? (
    <BundleConfirmationPage isCobundling={isCobundling} />
  ) : (
    <Flex width="full" align="center" justifyContent="center" mt={24}>
      <Box mx={5} maxWidth="550px">
        <FormikStepper
          loading={loading}
          initialValues={{
            name: '',
            emails: ['', '', ''],
            product: 'placeholder',
            additionalProducts: ['', ''],
            price: 1,
            savings: '',
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .max(250, 'Must be 250 characters or less')
              .required('Required'),
            emails: Yup.array().of(
              Yup.string()
                .email('Email is invalid')
                .test(
                  'ownEmailCheck',
                  'You are already a co-bundler',
                  cobndlrEmail =>
                    cobndlrEmail !== undefined
                      ? cobndlrEmail.toLowerCase() !== user.email.toLowerCase()
                      : true
                )
            ),
            product: Yup.string()
              .trim()
              .max(250, 'Must be 250 characters or less')
              .required('Please provide at least one product'),
            additionalProducts: Yup.array().of(
              Yup.string().trim().max(250, 'Must be 250 characters or less')
            ),
            price: Yup.number()
              .min(1, 'Price must be at least $1')
              .required('Required'),
          })}
          onSubmit={async values => {
            console.log('values before cleanup:', values);

            const trimmedName = values.name.trim();

            // Yup email already checks for spaces before/after
            const trimmedEmails = values.emails.filter(
              email => email.trim() !== ''
            );

            const trimmedAdditionalProducts = values.additionalProducts
              .filter(product => product.trim() !== '')
              .map(product => product.trim());

            if (trimmedEmails.length > 0) {
              setIsCobundling(true);
            }

            createBundle({
              variables: {
                fields: {
                  name: trimmedName,
                  cobundlers: trimmedEmails,
                  products: [
                    values.product.trim(),
                    ...trimmedAdditionalProducts,
                  ],

                  // transforming to cents to avoid floating point number manipulation
                  price_cents: Math.round(values.price * 100),
                  savings_cents: !values.savings
                    ? 0
                    : Math.round(values.savings * 100),
                },
              },
            });
          }}
        >
          <FormikStep>{namePage}</FormikStep>
          <FormikStep>
            <EmailsPage user={user} />
          </FormikStep>
          <FormikStep>
            <ProductsPage />
          </FormikStep>
          <FormikStep>
            <PricePage />
          </FormikStep>
        </FormikStepper>
      </Box>
    </Flex>
  );
};

export function FormikStep({ children, ...props }) {
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  return (
    <Formik
      {...props}
      onSubmit={async (values, helpers) => {
        // emails page starts with inputs touched, so as soon as you type it's an error until you type a valid email;
        if (step === 0) {
          helpers.setFieldTouched('emails', false);
        }

        if (step === 1) {
          helpers.setValues({ ...values, product: '' });
          helpers.setFieldTouched('product', false);
        }

        // can't have an empty price initial value since the "name bundle" page submit will stop advancing due
        // to price not passing the validation rules, even if the price page is not visible yet; and defaulting
        // price to 1 and showing it on the page is not ideal, hence this
        if (step === 2) {
          helpers.setValues({ ...values, price: '' });
          helpers.setFieldTouched('price', false);
        }

        if (isLastStep()) {
          await props.onSubmit(values, helpers);
        } else {
          setStep(s => s + 1);
        }
      }}
    >
      {({ isSubmitting, values, setValues }) => (
        <Form autoComplete="off">
          {React.cloneElement(currentChild, { values, setValues })}
          <Flex align="center" direction="column">
            <Button
              disabled={isSubmitting}
              my={6}
              textAlign="center"
              type="submit"
              isLoading={isSubmitting || props.loading ? true : false}
            >
              {isLastStep() ? 'Create Bundle' : 'Next'}
            </Button>
            {step === 1 ? (
              <Text color="gray.400">or, continue without partners</Text>
            ) : null}
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export default CreateBundle;
