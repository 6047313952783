import { gql } from '@apollo/client';

const GET_BUNDLES = gql`
  query {
    bundles {
      _id
      name
      external_id
      originator_email
      cobundlers {
        email
        joined
      }
      ready
      products {
        _id
        email
        products
      }
      prices {
        _id
        email
        price_cents
      }
      total_price_cents
      url
      active_sub_count
      mrr_in_cents
      lifetime_earnings
    }
  }
`;

export default GET_BUNDLES;
