import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import { UserContext } from '../../App';
import SET_PASSWORD from '../../graphql/mutations/setPassword.js';
import ErrorMessage from './ErrorMessage';

const SetPassword = () => {
  const { token } = useParams();
  const [user] = useContext(UserContext);
  let history = useHistory();
  const [show, setShow] = useState(false);
  const handleToggleClick = () => setShow(!show);

  useEffect(() => {
    if (user.access_token) {
      history.push('/');
    }
  }, [history, user.access_token]);

  const [setPassword, { loading, error }] = useMutation(SET_PASSWORD, {
    onCompleted() {
      console.log('New password set!');
      history.push('/login');
    },
    onError() {},
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmedPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(10, 'Must be at least ten characters')
        .max(64, 'Must be at most 64 characters')
        .required('The password is required'),
      confirmedPassword: Yup.string().test(
        'passwords-match',
        'Passwords must match',
        function (value) {
          return this.parent.password === value;
        }
      ),
    }),
    onSubmit: values => {
      setPassword({ variables: { password: values.password, token } });
    },
  });

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Box mx={5} width="90%" maxWidth="450px">
        <Box textAlign="center">
          <Heading>Set a new password</Heading>
        </Box>
        <Box mt={6} textAlign="left">
          <Text color="gray.400">
            Please enter a password that is at least ten characters.
          </Text>
        </Box>
        <Box mt={8} textAlign="left">
          <form onSubmit={formik.handleSubmit}>
            {error && <ErrorMessage error={error} />}
            <FormControl
              isInvalid={formik.errors.password && formik.touched.password}
            >
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? 'text' : 'password'}
                  variant="flushed"
                  placeholder="New Password"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    variant="outline"
                    h="1.75rem"
                    size="sm"
                    onClick={handleToggleClick}
                  >
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={
                formik.errors.confirmedPassword &&
                formik.touched.confirmedPassword
              }
            >
              <Input
                type="password"
                variant="flushed"
                placeholder="Confirm Password"
                id="confirmedPassword"
                name="confirmedPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmedPassword}
              />
              <FormErrorMessage>
                {formik.errors.confirmedPassword}
              </FormErrorMessage>
            </FormControl>
            <Box textAlign="center">
              <Button mt={6} type="submit" isLoading={loading ? true : false}>
                Set New Password
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default SetPassword;
