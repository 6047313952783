import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';

import AccountDetails from '../common/AccountDetails';

const Settings = () => {
  return (
    <Flex width="full" align="center" justifyContent="left" mt={24}>
      <Box mx={5} maxWidth="1200px">
        <Box mb={16} textAlign="left">
          <Heading as="h2" size="xl">
            Your Settings
          </Heading>
        </Box>
        <AccountDetails />
      </Box>
    </Flex>
  );
};

export default Settings;
