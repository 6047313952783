import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Flex, Heading } from '@chakra-ui/react';

import { UserContext } from '../../App';
import Subscriptions from './Subscriptions';

const Home = () => {
  const [user] = useContext(UserContext);
  if (!user.access_token) {
    return <Redirect to="login" />;
  }

  return (
    <Flex width="full" align="center" justifyContent="left" mt={24}>
      <Box mx={5} maxWidth="1200px">
        <Box textAlign="left">
          <Heading as="h2" size="xl">
            Your Account
          </Heading>
        </Box>
        <Subscriptions />
      </Box>
    </Flex>
  );
};

export default Home;
