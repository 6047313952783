import React from 'react';
import { Center, Spinner as ChakraSpinner } from '@chakra-ui/react';

const Spinner = () => {
  return (
    <Center mt={24}>
      <ChakraSpinner variant="bundle-page-load" />
    </Center>
  );
};

export default Spinner;
