import React from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Center, Flex, Heading, Text } from '@chakra-ui/react';

import GET_BUNDLE from '../../graphql/queries/bundle';
import Spinner from '../common/Spinner';

const CheckoutSuccess = () => {
  const { bundlecode } = useParams();
  let history = useHistory();

  const { loading, error, data } = useQuery(GET_BUNDLE, {
    variables: { external_id: bundlecode },
  });

  if (error) {
    console.error(error);
  }

  const invalidBundleCode = (
    <Center mt={24}>
      <Text color="gray.400">Cannot retrieve details about the bundle! :(</Text>
    </Center>
  );

  const subConfirmationPage = (
    <Flex width="full" align="center" justifyContent="center">
      <Box mx={5} my={30} maxWidth="1400px">
        <Box textAlign="center">
          <Heading color="gray.300">Subscription Confirmed!</Heading>
        </Box>
        <Box mt={4} textAlign="left">
          <Text fontSize="2xl" fontWeight="600" color="gray.600">
            {data ? data.bundle.name : null} is now processing your subscription
            benefits!
          </Text>
        </Box>
        <Box mt={2} textAlign="left">
          <Text fontSize="xl" color="gray.100">
            Please allow 24-48 hours for each creator to get you access to their
            products, including{' '}
            {data
              ? data.bundle.products
                  .map(item => item.products)
                  .flat(1)
                  .join(', ')
              : null}
            .
          </Text>
          <Text mt={1} fontSize="xl" color="gray.100">
            You will receive separate emails inviting you to each one :)
          </Text>
        </Box>
        <Box textAlign="center">
          <Button mt={8} onClick={() => history.push('/')}>
            Continue
          </Button>
        </Box>
        <Box mt={8} textAlign="left">
          <Text fontSize="2xl" color="gray.600">
            You have received an email from BundlePay where you can manage your
            subscription, or simply click the button above.
          </Text>
        </Box>
        <Box mt={4} textAlign="left">
          <Text fontSize="2xl" color="gray.600">
            Please email us at support@bundlepay.co if you have any questions or
            concerns!
          </Text>
        </Box>
      </Box>
    </Flex>
  );

  return loading ? (
    <Spinner />
  ) : error ? (
    invalidBundleCode
  ) : (
    subConfirmationPage
  );
};

export default CheckoutSuccess;
