import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const ConsumerRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return user ? (
          user.creator === false ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default ConsumerRoute;
