import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Button, Center, Flex, Heading, Text } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';

import GET_BUNDLE from '../../graphql/queries/bundle';
import Terms from '../common/Terms';
import Spinner from '../common/Spinner';

const Bundle = () => {
  let history = useHistory();
  const { bundlecode } = useParams();

  const { loading, error, data } = useQuery(GET_BUNDLE, {
    variables: { external_id: bundlecode },
  });

  if (error) {
    console.error(error);
  }

  const invalidBundleCode = (
    <Center mt={24}>
      <Text color="gray.400">Invalid bundle link! :(</Text>
    </Center>
  );

  const bundleDetails = (
    <Flex width="full" align="center" justifyContent="center">
      <Box mx={5} my={30} maxWidth="1400px">
        <Box textAlign="center">
          <Heading color="gray.300" as="u">
            Get the {data ? data.bundle.name : null}
          </Heading>
        </Box>
        <Flex my={10} direction="row" wrap="wrap" justifyContent="space-evenly">
          {data
            ? data.bundle.products
                .map(item => item.products)
                .flat(1)
                .map((product, index) => (
                  <Text
                    mx={6}
                    mb={4}
                    fontSize="lg"
                    fontWeight="600"
                    key={index}
                  >
                    {product}
                  </Text>
                ))
            : null}
        </Flex>
        <Box textAlign="center">
          <Button
            mt={1}
            onClick={() =>
              history.push(
                `/subscribe/${bundlecode}?bundlename=${data.bundle.name}`
              )
            }
          >
            Get The Bundle
          </Button>
        </Box>
        <Box mt={4} textAlign="center">
          <Text fontSize="2xl" fontWeight="600" color="gray.100">
            {data ? `$${data.bundle.total_price_cents / 100}/month` : null}
          </Text>
        </Box>
        {data && data.bundle.total_savings_cents > 0 ? (
          <Box my={2} textAlign="center">
            <Text fontSize="2xl" color="gray.200">
              {data
                ? `Save $${
                    data.bundle.total_savings_cents / 100
                  }/month in total`
                : null}
            </Text>
          </Box>
        ) : null}
        <Box my={4} textAlign="center">
          <Text fontSize="sm" color="gray.400">
            Bundle helps you save money on your subscriptions by bundling them
            together
          </Text>
          <Text fontSize="sm" color="gray.400">
            Easily manage your subscriptions right from your dashboard.
          </Text>
          <Terms fontSize="sm" />
        </Box>
      </Box>
    </Flex>
  );

  return loading ? <Spinner /> : error ? invalidBundleCode : bundleDetails;
};

export default Bundle;
