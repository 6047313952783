import React, { useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  Link,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/client';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import { UserContext } from '../../App';
import LOGIN from '../../graphql/mutations/login';
import ErrorMessage from '../common/ErrorMessage';

const Login = () => {
  const [user, setUser] = useContext(UserContext);
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };

  // redirect to / if logged in user navigates here by typing URL in (no state passed in that case)
  // this effect gets called even after the mutation returns, hence that state condition, otherwise a login will always end up to / even if there is a return URL set
  useEffect(() => {
    if (user.access_token && !location.state) {
      history.push('/');
    }
  }, [history, user.access_token, location.state]);

  const [login, { loading, error }] = useMutation(LOGIN, {
    onCompleted({ login }) {
      if (login.access_token) {
        setUser({
          access_token: login.access_token,
          email: login.email,
          confirmed: login.confirmed,
          connected_for_payment: login.connected_for_payment,
          creator: login.creator,
        });
        history.replace(from);
      } else {
        console.error('No access token returned upon login.');
      }
    },
    onError() {},
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: values => {
      login({
        variables: {
          fields: { email: values.email, password: values.password },
        },
      });
    },
  });

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Box mx={5} width="90%" maxWidth="350px">
        <Box textAlign="center">
          <Heading>Log in</Heading>
        </Box>
        <Box my={4} textAlign="left">
          <form onSubmit={formik.handleSubmit}>
            {error && <ErrorMessage error={error} />}
            <FormControl>
              <Input
                variant="flushed"
                placeholder="Email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </FormControl>
            <FormControl mt={6}>
              <InputGroup>
                <Input
                  variant="flushed"
                  type="password"
                  placeholder="Password"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
              </InputGroup>
            </FormControl>
            <Box textAlign="center">
              <Button mt={4} type="submit" isLoading={loading ? true : false}>
                Log in
              </Button>
            </Box>
          </form>
        </Box>
        <Box textAlign="center">
          <Link as={RouterLink} to="/forgot" mt={4} color="blue.400">
            Forgot password?
          </Link>
        </Box>
      </Box>
    </Flex>
  );
};

export default Login;
