import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Button, Stack } from '@chakra-ui/react';

import MenuItem from './MenuItem';
import { UserContext } from '../../App';
import Logout from './Logout';

const MenuLinks = ({ isOpen, location }) => {
  const [user] = useContext(UserContext);
  const { pathname } = location;

  const authButton = () => {
    if (user.access_token) {
      return (
        <MenuItem isLast>
          <Logout />
        </MenuItem>
      );
    }
    return pathname === '/login' ? (
      <MenuItem to="/register" isLast>
        <Button variant="outline">Create Account</Button>
      </MenuItem>
    ) : (
      <MenuItem to="/login" isLast>
        <Button variant="outline">Sign In</Button>
      </MenuItem>
    );
  };

  return (
    <Box
      display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
      flexBasis={{ base: '100%', md: 'auto' }}
    >
      <Stack
        spacing={8}
        align="center"
        justify={['center', 'space-between', 'flex-end', 'flex-end']}
        direction={['column', 'row', 'row', 'row']}
        pt={[4, 4, 0, 0]}
      >
        {/* <MenuItem to="/about">About</MenuItem> */}
        {authButton()}
      </Stack>
    </Box>
  );
};

export default withRouter(MenuLinks);
