import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import Login from './components/creator/Login';
import Register from './components/creator/Register';
import Home from './components/creator/Home';
import Refresh from './components/creator/Refresh';
import Connected from './components/creator/Connected';
import Verify from './components/common/Verify';
import Layout from './components/common/Layout';
import Subscribers from './components/creator/Subscribers';
import CreatorSettings from './components/creator/Settings';
import ConsumerSettings from './components/consumer/Settings';
import CreateBundle from './components/creator/CreateBundle';
import Invite from './components/creator/Invite';
import Join from './components/creator/Join';
import Bundle from './components/consumer/Bundle';
import Subscribe from './components/consumer/Subscribe';
import Payment from './components/consumer/Payment';
import CreatorRoute from './components/creator/CreatorRoute';
import ConsumerRoute from './components/consumer/ConsumerRoute';
import ConsumerHome from './components/consumer/Home';
import CheckoutSuccess from './components/consumer/CheckoutSuccess';
import CheckoutCanceled from './components/consumer/CheckoutCanceled';
import Spinner from './components/common/Spinner';
import ForgotPassword from './components/common/ForgotPassword';
import SetPassword from './components/common/SetPassword';
import bundlePayTheme from './theme';
import ChangePassword from './components/common/ChangePassword';

export const UserContext = React.createContext([]);

function App() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkRefreshToken() {
      const fetchResult = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/refresh_token`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (fetchResult.ok) {
        const result = await fetchResult.json();
        if (!result.error && result.access_token) {
          setUser({
            access_token: result.access_token,
            email: result.email,
            confirmed: result.confirmed,
            connected_for_payment: result.connected_for_payment,
            creator: result.creator,
          });
        }
      } else {
        console.error(fetchResult);
      }
      setLoading(false);
    }
    checkRefreshToken();
  }, []);

  const authLink = setContext((_, { headers }) => {
    const token = user.access_token ? user.access_token : '';
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
    credentials: 'include',
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  return (
    <ApolloProvider client={client}>
      <UserContext.Provider value={[user, setUser]}>
        <ChakraProvider theme={bundlePayTheme}>
          <BrowserRouter>
            <Layout>
              {loading ? (
                <Spinner />
              ) : (
                <Switch>
                  <Route path="/register" component={Register} />
                  <Route path="/login" component={Login} />
                  <CreatorRoute
                    path="/stripe/refresh"
                    component={Refresh}
                    user={user}
                  />
                  <CreatorRoute
                    path="/stripe/return"
                    component={Connected}
                    user={user}
                  />
                  <CreatorRoute
                    path="/subscribers"
                    component={Subscribers}
                    user={user}
                  />
                  <CreatorRoute
                    path="/create"
                    component={CreateBundle}
                    user={user}
                  />
                  <CreatorRoute
                    path="/invite/:bundlecode"
                    component={Invite}
                    user={user}
                  />
                  <CreatorRoute
                    path="/join/:bundlecode"
                    component={Join}
                    user={user}
                  />
                  <CreatorRoute
                    path="/settings"
                    component={CreatorSettings}
                    user={user}
                  />
                  <CreatorRoute
                    path="/changepassword"
                    component={ChangePassword}
                    user={user}
                    exact
                  />

                  <Route
                    path="/bundle/:bundlename/:bundlecode"
                    component={Bundle}
                  />
                  <Route path="/verify/:token" component={Verify} />
                  <Route path="/setpassword/:token" component={SetPassword} />
                  <Route
                    path="/subscribe/:bundlecode"
                    exact
                    component={Subscribe}
                  />
                  <Route path="/forgot" component={ForgotPassword} />
                  <ConsumerRoute
                    path="/subscribe/:bundlecode/payment"
                    component={Payment}
                    user={user}
                  />
                  <ConsumerRoute
                    path="/subscribe/:bundlecode/success"
                    component={CheckoutSuccess}
                    user={user}
                  />
                  <ConsumerRoute
                    path="/subscribe/:bundlecode/canceled"
                    component={CheckoutCanceled}
                    user={user}
                  />
                  <ConsumerRoute
                    path="/consumer/settings"
                    exact
                    component={ConsumerSettings}
                    user={user}
                  />
                  <ConsumerRoute
                    path="/consumer/changepassword"
                    exact
                    component={ChangePassword}
                    user={user}
                  />
                  <ConsumerRoute
                    path="/consumer"
                    exact
                    component={ConsumerHome}
                    user={user}
                  />

                  <Route path="/" exact component={Home} />
                </Switch>
              )}
            </Layout>
          </BrowserRouter>
        </ChakraProvider>
      </UserContext.Provider>
    </ApolloProvider>
  );
}

export default App;
