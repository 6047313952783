import { gql } from '@apollo/client';

const LOGIN = gql`
  mutation Login($fields: LoginInput!) {
    login(fields: $fields) {
      access_token
      email
      confirmed
      connected_for_payment
      creator
    }
  }
`;

export default LOGIN;
