import React, { useState } from 'react';
import { Box, Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import JOIN_BUNDLE from '../../graphql/mutations/joinBundle';
import ProductsPage from './bundleForm/ProductsPage';
import PricePage from './bundleForm/PricePage';

const BundleJoinedConfirmationPage = props => {
  let history = useHistory();
  return (
    <VStack maxWidth="700px" mt={24}>
      <Box px={5} textAlign="left">
        <Heading>You successfully joined the bundle!</Heading>
      </Box>
      <Box p={5} textAlign="left">
        <Text color="gray.400">
          As soon as all the other co-bundlers join we will provide your Bundle
          link :). If you were the last to join, you can access the link in your
          dashboard now, see your earnings in Stripe, and manage your
          subscribers. Click the link below to get started.
        </Text>
      </Box>
      <Button onClick={() => history.push('/')}>Continue</Button>
    </VStack>
  );
};

const Join = () => {
  const { bundlecode } = useParams();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [joinBundle, { loading }] = useMutation(JOIN_BUNDLE, {
    onCompleted() {
      console.log('Bundle joined successfully!');
      setShowConfirmation(true);
    },
    onError() {
      console.error('Joining the bundle failed!');
    },
  });

  return showConfirmation ? (
    <BundleJoinedConfirmationPage />
  ) : (
    <Flex width="full" align="center" justifyContent="center" mt={24}>
      <Box mx={5} maxWidth="550px">
        <FormikStepper
          loading={loading}
          initialValues={{
            product: '',
            additionalProducts: ['', ''],
            price: 1,
            savings: '',
          }}
          validationSchema={Yup.object({
            product: Yup.string()
              .trim()
              .max(250, 'Must be 250 characters or less')
              .required('Please provide at least one product'),
            additionalProducts: Yup.array().of(
              Yup.string().trim().max(250, 'Must be 250 characters or less')
            ),
            price: Yup.number()
              .min(1, 'Price must be at least $1')
              .required('Required'),
          })}
          onSubmit={async values => {
            console.log('values before cleanup:', values);

            const trimmedAdditionalProducts = values.additionalProducts
              .filter(product => product.trim() !== '')
              .map(product => product.trim());

            joinBundle({
              variables: {
                fields: {
                  external_id: bundlecode,
                  products: [
                    values.product.trim(),
                    ...trimmedAdditionalProducts,
                  ],

                  // transforming to cents to avoid floating point number manipulation
                  price_cents: Math.round(values.price * 100),
                  savings_cents: !values.savings
                    ? 0
                    : Math.round(values.savings * 100),
                },
              },
            });
          }}
        >
          <FormikStep>
            <ProductsPage />
          </FormikStep>
          <FormikStep>
            <PricePage />
          </FormikStep>
        </FormikStepper>
      </Box>
    </Flex>
  );
};

export function FormikStep({ children, ...props }) {
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  return (
    <Formik
      {...props}
      onSubmit={async (values, helpers) => {
        // can't have an empty price initial value since the "name bundle" page submit will stop advancing due
        // to price not passing the validation rules, even if the price page is not visible yet; and defaulting
        // price to 1 and showing it on the page is not ideal, hence this
        if (step === 0) {
          helpers.setValues({ ...values, price: '' });
          helpers.setFieldTouched('price', false);
        }

        if (isLastStep()) {
          await props.onSubmit(values, helpers);
        } else {
          setStep(s => s + 1);
        }
      }}
    >
      {({ isSubmitting, values, setValues }) => (
        <Form autoComplete="off">
          {React.cloneElement(currentChild, { values, setValues })}
          <Flex align="center" direction="column">
            <Button
              disabled={isSubmitting}
              my={6}
              textAlign="center"
              type="submit"
              isLoading={isSubmitting || props.loading ? true : false}
            >
              {isLastStep() ? 'Join Bundle' : 'Next'}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export default Join;
