import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import { useMutation } from '@apollo/client';

import GET_SUBSCRIPTIONS_FOR_CONSUMER from '../../graphql/queries/subscriptions';
import CANCEL_SUBSCRIPTION from '../../graphql/mutations/cancelSubscription';
import Spinner from '../common/Spinner';

const Subscriptions = () => {
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [cancelingBundleName, setCancelingBundleName] = useState('');
  const [cancelingSubId, setCancelingSubId] = useState('');
  const onDialogClose = () => setIsDialogOpen(false);
  const cancelRef = useRef();

  // useLazyQuery + useEffect instead of just useQuery because of bs error: https://github.com/apollographql/apollo-client/issues/6209
  const [myQueryExecutor, { error, data }] = useLazyQuery(
    GET_SUBSCRIPTIONS_FOR_CONSUMER,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted() {
        setLoading(false);
      },
    }
  );

  // execute query on component mount
  useEffect(() => {
    myQueryExecutor();
  }, [myQueryExecutor]);

  if (error) {
    console.error(error);
  }

  const [cancelSubscription, { loading: cancelSubLoading }] = useMutation(
    CANCEL_SUBSCRIPTION,
    {
      onCompleted() {
        console.log('Sub canceled successfully!');

        // TODO: show confirmation notification?

        // refresh the table
        myQueryExecutor();
      },
      onError() {
        console.error('Canceling the sub failed!');
      },
    }
  );

  const onConfirmationSubmit = () => {
    cancelSubscription({ variables: { subscriptionId: cancelingSubId } });
    setIsDialogOpen(false);
  };

  const CancelSubButton = props => {
    return (
      <Button
        size="xs"
        variant="solid"
        bg="#0040dd"
        color="gray.50"
        _hover={{
          bg: '#164ede',
        }}
        _active={{
          bg: '#2c5fde',
        }}
        onClick={() => {
          setIsDialogOpen(true);
          setCancelingBundleName(props.bundleName);
          setCancelingSubId(props.subscriptionId);
        }}
      >
        Cancel
      </Button>
    );
  };

  const confirmationDialog = (
    <AlertDialog
      isOpen={isDialogOpen}
      leastDestructiveRef={cancelRef}
      onClose={onDialogClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg="gray.800">
          <AlertDialogHeader color="gray.200" fontSize="lg" fontWeight="bold">
            Cancel Subscription
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to cancel your subscription to the{' '}
            <Text as="span" fontWeight="700">
              {cancelingBundleName}
            </Text>{' '}
            bundle?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onDialogClose}>
              No
            </Button>
            <Button
              bg="#0040dd"
              color="gray.50"
              _hover={{
                bg: '#164ede',
              }}
              _active={{
                bg: '#2c5fde',
              }}
              onClick={onConfirmationSubmit}
              ml={3}
            >
              Yes, Cancel Subscription
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );

  const subsTable = (
    <Table my={6} size="sm">
      <Thead>
        <Tr>
          <Th>Bundle</Th>
          <Th>Products</Th>
          <Th>Status</Th>
          <Th>Price</Th>
          <Th>Active Since</Th>
          <Th>Cancel</Th>
        </Tr>
      </Thead>
      <Tbody color="gray.400">
        {data
          ? data.subscriptionsForConsumer.map(sub => (
              <Tr key={sub._id}>
                <Td>{sub.bundle_name}</Td>
                <Td>{sub.products.join(', ')}</Td>
                <Td>{sub.active ? 'Active' : 'Canceled'}</Td>
                <Td>${sub.price_cents / 100}</Td>
                <Td>{format(parseISO(sub.created_at), 'PPP')}</Td>
                <Td>
                  {sub.active ? (
                    <CancelSubButton
                      bundleName={sub.bundle_name}
                      subscriptionId={sub._id}
                    />
                  ) : (
                    'Canceled'
                  )}
                </Td>
              </Tr>
            ))
          : null}
      </Tbody>
    </Table>
  );

  const dashboard = cancelSubLoading ? (
    loading
  ) : (
    <>
      <Box mt={24} textAlign="left">
        <Heading as="h2" size="lg" color="gray.500">
          Your Bundles
        </Heading>
      </Box>
      {subsTable}
      {confirmationDialog}
    </>
  );

  const noSubscriptions = (
    <Box my={8} textAlign="left">
      <Text color="gray.400">You have not subscribed to any bundles yet.</Text>
    </Box>
  );

  return loading ? (
    <Spinner />
  ) : data.subscriptionsForConsumer.length ? (
    dashboard
  ) : (
    noSubscriptions
  );
};

export default Subscriptions;
