import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Center, Flex, Box, Heading, Text } from '@chakra-ui/react';

import GET_BUNDLE from '../../graphql/queries/bundle';
import Spinner from '../common/Spinner';

const Invite = () => {
  let history = useHistory();
  const { bundlecode } = useParams();
  const { loading, error, data } = useQuery(GET_BUNDLE, {
    variables: { external_id: bundlecode },
  });

  if (error) {
    console.error(error);
  }

  const invalidBundleCode = (
    <Center mt={24}>
      <Text color="gray.400">Invalid bundle invite! :(</Text>
    </Center>
  );

  const invitation = (
    <Flex width="full" align="center" justifyContent="center" mt={24}>
      <Box mx={5} maxWidth="500px">
        <Box textAlign="left">
          <Heading>
            You're invited to join the {data ? data.bundle.name : null}!
          </Heading>
        </Box>
        <Box my={10} textAlign="left">
          <Text color="gray.400">
            Congratulations, you've been invited to join the{' '}
            {data ? (
              <Text as="span" fontWeight="700">
                {data.bundle.name}
              </Text>
            ) : null}{' '}
            with everyone below. To confirm your participation, please create
            your account and provide your subscription details.
          </Text>
        </Box>
        <Box my={4} textAlign="center">
          {data
            ? [
                ...data.bundle.cobundlers.map(cobundler => cobundler.email),
                data.bundle.originator_email,
              ].map((email, index) => (
                <Text key={index} color="gray.400">
                  {email}
                </Text>
              ))
            : null}
        </Box>
        <Box textAlign="center">
          <Button mt={1} onClick={() => history.push('/register')}>
            Let's Do This!
          </Button>
        </Box>
      </Box>
    </Flex>
  );

  return loading ? <Spinner /> : error ? invalidBundleCode : invitation;
};

export default Invite;
