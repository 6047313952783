import React from 'react';
import { Link, Text } from '@chakra-ui/react';

const Terms = props => {
  return (
    <Text color="gray.400" {...props}>
      By signing up, you agree to our{' '}
      <Link
        textDecoration="underline"
        href="https://bundlepay.co/terms"
        isExternal
      >
        Terms &amp; Conditions
      </Link>{' '}
      and{' '}
      <Link
        textDecoration="underline"
        href="https://bundlepay.co/privacy-policy"
        isExternal
      >
        Privacy Policy
      </Link>
      .
    </Text>
  );
};

export default Terms;
