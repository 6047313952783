import React, { useContext } from 'react';
import { Button, Link, VStack } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { UserContext } from '../../App';

const SidebarItem = ({ children, to = '/' }) => {
  return (
    <Link
      as={NavLink}
      to={to}
      activeStyle={{
        fontWeight: 'bold',
      }}
      exact
      _hover={{ textDecoration: 'none' }}
    >
      {children}
    </Link>
  );
};

const SidebarLinks = props => {
  const [user] = useContext(UserContext);
  return (
    <VStack
      spacing="6"
      align="flex-start"
      direction={['column']}
      p={8}
      {...props}
    >
      <SidebarItem to={user && user.creator ? '/' : '/consumer'}>
        <Button
          variant="link"
          fontWeight="inherit"
          _hover={{ textDecoration: 'none' }}
        >
          Your Bundles
        </Button>
      </SidebarItem>
      {user && user.creator ? (
        <SidebarItem to="/subscribers">
          <Button
            variant="link"
            fontWeight="inherit"
            _hover={{ textDecoration: 'none' }}
          >
            Subscribers
          </Button>
        </SidebarItem>
      ) : null}
      <SidebarItem
        to={user && !user.creator ? '/consumer/settings' : '/settings'}
      >
        <Button
          variant="link"
          fontWeight="inherit"
          _hover={{ textDecoration: 'none' }}
        >
          Settings
        </Button>
      </SidebarItem>
    </VStack>
  );
};

export default SidebarLinks;
