import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';

import { UserContext } from '../../App';
import CHANGE_PASSWORD from '../../graphql/mutations/changePassword.js';
import LOGOUT from '../../graphql/mutations/logout.js';
import ErrorMessage from './ErrorMessage';

const ChangePassword = () => {
  const [show, setShow] = useState(false);
  const handleToggleClick = () => setShow(!show);
  const [, setUser] = useContext(UserContext);

  const [logout] = useMutation(LOGOUT, {
    onCompleted() {
      setUser({ access_token: '' });
    },
    onError() {
      console.error('Logout error!');
      // TODO: feedback to the user
    },
  });

  const [changePassword, { loading, error }] = useMutation(CHANGE_PASSWORD, {
    onCompleted() {
      console.log('Password changed!');
      logout();
    },
    onError() {},
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmedPassword: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required(
        'The current password is required'
      ),
      newPassword: Yup.string()
        .min(10, 'Must be at least ten characters')
        .max(64, 'Must be at most 64 characters')
        .required('The new password is required')
        .test(
          'new-password-different',
          'New password must not match current',
          function (value) {
            return this.parent.currentPassword !== value;
          }
        ),
      confirmedPassword: Yup.string().test(
        'passwords-match',
        'Passwords must match',
        function (value) {
          return this.parent.newPassword === value;
        }
      ),
    }),
    onSubmit: values => {
      changePassword({
        variables: {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        },
      });
    },
  });

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Box mx={5} width="90%" maxWidth="450px">
        <Box textAlign="center">
          <Heading>Change your password</Heading>
        </Box>
        <Box mt={6} textAlign="left">
          <Text color="gray.400">
            Please enter a new password that is at least ten characters.
          </Text>
        </Box>
        <Box mt={8} textAlign="left">
          <form onSubmit={formik.handleSubmit}>
            {error && <ErrorMessage error={error} />}
            <FormControl
              mt={4}
              isInvalid={
                formik.errors.currentPassword && formik.touched.currentPassword
              }
            >
              <Input
                type="password"
                variant="flushed"
                placeholder="Current Password"
                id="currentPassword"
                name="currentPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.currentPassword}
              />
              <FormErrorMessage>
                {formik.errors.currentPassword}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={
                formik.errors.newPassword && formik.touched.newPassword
              }
            >
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? 'text' : 'password'}
                  variant="flushed"
                  placeholder="New Password"
                  id="newPassword"
                  name="newPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    variant="outline"
                    h="1.75rem"
                    size="sm"
                    onClick={handleToggleClick}
                  >
                    {show ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
            </FormControl>
            <FormControl
              mt={4}
              isInvalid={
                formik.errors.confirmedPassword &&
                formik.touched.confirmedPassword
              }
            >
              <Input
                type="password"
                variant="flushed"
                placeholder="Confirm New Password"
                id="confirmedPassword"
                name="confirmedPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmedPassword}
              />
              <FormErrorMessage>
                {formik.errors.confirmedPassword}
              </FormErrorMessage>
            </FormControl>
            <Box textAlign="center">
              <Button mt={6} type="submit" isLoading={loading ? true : false}>
                Change Password
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Flex>
  );
};

export default ChangePassword;
