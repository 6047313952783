import React from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';

import CustomTextInput from './CustomTextInput';

const ProductsPage = props => {
  const addProductInput = () => {
    const additionalProducts = [...props.values.additionalProducts, ''];
    props.setValues({ ...props.values, additionalProducts });
  };
  return (
    <>
      <Box textAlign="left">
        <Heading>What product(s) are you including?</Heading>
      </Box>
      <Box my={4} textAlign="left">
        <Text color="gray.400">
          Include the names of{' '}
          <Text as="span" fontWeight="700">
            only your products
          </Text>{' '}
          on separate lines below. Your{' '}
          <Text as="span" fontWeight="700">
            co-bundlers will list theirs separately
          </Text>
          .
        </Text>
      </Box>
      <CustomTextInput variant="flushed" placeholder="Product" name="product" />
      {props.values.additionalProducts.map((_, i) => {
        return (
          <CustomTextInput
            variant="flushed"
            placeholder="Product"
            name={`additionalProducts.${i}`}
            key={i}
          />
        );
      })}
      <Box mt={3} textAlign="center">
        <Button
          color="gray.400"
          fontWeight={400}
          variant="link"
          _hover={{ textDecoration: 'none' }}
          onClick={addProductInput}
        >
          + add more products.
        </Button>
      </Box>
    </>
  );
};

export default ProductsPage;
