import { gql } from '@apollo/client';

const CONNECT_ONBOARDING = gql`
  mutation {
    connectOnboarding {
      url
    }
  }
`;

export default CONNECT_ONBOARDING;
