import React from 'react';
import { Box, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';

const ErrorMessage = ({ error }) => {
  const message = error => {
    if (error.networkError) {
      return 'Server error! Please try again later.';
    }
    return error.message;
  };

  return (
    <Box my={4}>
      <Alert status="error" borderRadius={4}>
        <AlertIcon />
        <AlertDescription>{message(error)}</AlertDescription>
      </Alert>
    </Box>
  );
};

export default ErrorMessage;
