import React, { useState } from 'react';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import AccountDetails from '../common/AccountDetails';
import STRIPE_EXPRESS_DASHBOARD_LINK from '../../graphql/mutations/stripeExpressDashboardLink';
import Spinner from '../common/Spinner';

const Settings = () => {
  const [loading, setLoading] = useState(false);

  const [stripeExpressDashboardLink] = useMutation(
    STRIPE_EXPRESS_DASHBOARD_LINK,
    {
      onCompleted(data) {
        window.location.replace(data.stripeExpressDashboardLink);
      },
      onError() {
        setLoading(false);
      },
    }
  );

  return loading ? (
    <Spinner />
  ) : (
    <Flex width="full" align="center" justifyContent="left" mt={24}>
      <Box mx={5} maxWidth="1200px">
        <Box mb={16} textAlign="left">
          <Heading as="h2" size="xl">
            Your Settings
          </Heading>
        </Box>
        <AccountDetails />
        <Box mt={16}>
          <Heading as="h3" size="lg" color="gray.500">
            Billing
          </Heading>
          <Button
            mt={2}
            color="gray.800"
            variant="link"
            onClick={() => {
              setLoading(true);
              stripeExpressDashboardLink();
            }}
          >
            Update account information in Stripe
            <ExternalLinkIcon ml={2} color="gray.700" />
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

export default Settings;
