import React, { useContext } from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../App';

const AccountDetails = () => {
  const [user] = useContext(UserContext);
  let history = useHistory();
  return (
    <Box>
      <Heading as="h3" size="lg" color="gray.500">
        Account details
      </Heading>
      <Box mt={3}>
        <Text fontSize="lg">Email: {user.email}</Text>
        <Button
          mt={2}
          size="sm"
          onClick={() =>
            history.push(
              user && user.creator
                ? '/changepassword'
                : '/consumer/changepassword'
            )
          }
        >
          Change Password
        </Button>
      </Box>
    </Box>
  );
};

export default AccountDetails;
