import React from 'react';
import { VStack } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import Logo from './Logo';
import SidebarLinks from './SidebarLinks';

const Sidebar = props => {
  return (
    <VStack
      align="flex-start"
      borderRight="1px"
      borderColor="#d1d1d6"
      {...props}
    >
      <RouterLink to="/">
        <Logo p={4} align="center" />
      </RouterLink>
      <SidebarLinks />
    </VStack>
  );
};

export default Sidebar;
