import { gql } from '@apollo/client';

const STRIPE_ACCOUNT = gql`
  mutation {
    stripeAccount {
      account_id
      details_submitted
    }
  }
`;

export default STRIPE_ACCOUNT;
