import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Center, Link, Text } from '@chakra-ui/react';

import { UserContext } from '../../App';
import VERIFY_EMAIL from '../../graphql/mutations/verifyEmail';
import Spinner from './Spinner';

const Verify = () => {
  const { token } = useParams();
  const [user, setUser] = useContext(UserContext);

  const [verifyEmail, { loading }] = useMutation(VERIFY_EMAIL, {
    onCompleted() {
      setUser({ ...user, confirmed: true });
    },
    onError() {
      console.error('Verification error!');
    },
  });

  useEffect(() => {
    verifyEmail({ variables: { verificationToken: token } });
  }, [verifyEmail, token]);

  const failedVerification = (
    <Center mt={20}>
      <Text>
        Your account verification failed! Please try again or request another
        confirmation email when signed in.
      </Text>
    </Center>
  );
  const successfulVerification = (
    <Center mt={20}>
      <Text>
        Your account has been confirmed! Click{' '}
        <Link as={RouterLink} to="/login" color="blue.300">
          here
        </Link>{' '}
        to sign in.
      </Text>
    </Center>
  );

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>{user.confirmed ? successfulVerification : failedVerification}</>
      )}
    </>
  );
};

export default Verify;
