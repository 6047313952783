import { gql } from '@apollo/client';

const GET_SUBSCRIPTIONS_FOR_CONSUMER = gql`
  query {
    subscriptionsForConsumer {
      _id
      bundle_external_id
      bundle_name
      products
      consumer_email
      stripe_customer_id
      stripe_subscription_id
      price_cents
      active
      created_at
    }
  }
`;

export default GET_SUBSCRIPTIONS_FOR_CONSUMER;
