import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { UserContext } from '../../App';
import Register from './Register';
import LOGOUT from '../../graphql/mutations/logout';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Subscribe = props => {
  const { bundlecode } = useParams();
  let query = useQuery();
  const [user, setUser] = useContext(UserContext);
  let history = useHistory();

  useEffect(() => {
    if (user.access_token && user.creator === false) {
      // go to payment since current user is a consumer already, skipping register/login
      history.push(`/subscribe/${bundlecode}/payment`);
    }
  }, [bundlecode, history, user.access_token, user.creator]);

  const CreatorLoggedIn = props => {
    const [logout] = useMutation(LOGOUT, {
      onCompleted() {
        setUser({ access_token: '' });
      },
      onError() {
        console.error('Logout error!');
        // TODO: feedback to the user
      },
    });

    useEffect(() => {
      const timeoutID = window.setTimeout(() => {
        logout();
      }, 3000);
      return () => window.clearTimeout(timeoutID);
    }, [logout]);

    return (
      <div>
        Logging you out from your creator account. Please register or sign in
        with a consumer account on the next screen...
      </div>
    );
  };

  const consumerLoggedIn = <div>Redirecting to payment...</div>;

  return !user.access_token ? (
    <Register bundleName={query.get('bundlename')} />
  ) : user.creator ? (
    <CreatorLoggedIn />
  ) : (
    consumerLoggedIn
  );
};

export default Subscribe;
