import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useClipboard,
} from '@chakra-ui/react';
import { CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { UserContext } from '../../App';
import GET_BUNDLES from '../../graphql/queries/bundles';
import STRIPE_EXPRESS_DASHBOARD_LINK from '../../graphql/mutations/stripeExpressDashboardLink';
import Spinner from '../common/Spinner';

const Bundles = () => {
  let history = useHistory();
  const [user] = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  // useLazyQuery + useEffect instead of just useQuery because of bs error: https://github.com/apollographql/apollo-client/issues/6209
  const [myQueryExecutor, { error, data }] = useLazyQuery(GET_BUNDLES, {
    fetchPolicy: 'cache-and-network',
    onCompleted() {
      setLoading(false);
    },
  });

  // execute query on component mount
  useEffect(() => {
    myQueryExecutor();
  }, [myQueryExecutor]);

  if (error) {
    console.error(error);
  }

  const [stripeExpressDashboardLink] = useMutation(
    STRIPE_EXPRESS_DASHBOARD_LINK,
    {
      onCompleted(data) {
        window.location.replace(data.stripeExpressDashboardLink);
      },
      onError() {
        setLoading(false);
      },
    }
  );

  const createYourFirst = (
    <Flex width="full" align="center" justifyContent="center" mt={24}>
      <Box mx={5} maxWidth="500px">
        <Box textAlign="left">
          <Heading>Create your first Bundle!</Heading>
        </Box>
        <Box my={4} textAlign="left">
          <Text color="gray.400">
            Time to create your first Bundle. Simply click the large blue button
            below to get started.
          </Text>
        </Box>
        <Box my={6} textAlign="center">
          <Button mt={6} onClick={() => history.push('/create')}>
            + Bundle
          </Button>
        </Box>
      </Box>
    </Flex>
  );

  const hasUserJoinedBundle = bundle => {
    return (
      bundle.originator_email === user.email ||
      bundle.cobundlers.filter(cobndlr => cobndlr.email === user.email)[0]
        .joined
    );
  };

  const JoinButton = props => {
    return (
      <Button
        size="xs"
        variant="solid"
        bg="#e0f3e0"
        color="gray.600"
        _hover={{
          bg: '#c9f1c9',
        }}
        _active={{
          bg: '#a9f1a9',
        }}
        onClick={() => history.push(`join/${props.bundlecode}`)}
      >
        Join Bundle
      </Button>
    );
  };

  const CopyButton = props => {
    const { hasCopied, onCopy } = useClipboard(props.url);
    return (
      <Button
        size="xs"
        variant="outline"
        leftIcon={<CopyIcon />}
        onClick={onCopy}
      >
        {hasCopied ? 'Copied' : 'Copy'}
      </Button>
    );
  };

  const bundlesTable = (
    <Table my={6} size="sm">
      <Thead>
        <Tr>
          <Th>Bundle Name</Th>
          <Th>Bundle Link</Th>
          <Th>Total Price</Th>
          <Th>Your Price</Th>
          <Th>Your Products</Th>
          <Th>Your Co-Bundlers</Th>
          <Th>Status</Th>
          <Th>Active Subs</Th>
          <Th>Your MRR</Th>
          <Th>Lifetime Earnings</Th>
        </Tr>
      </Thead>
      <Tbody color="gray.400">
        {data
          ? data.bundles.map(bundle => (
              <Tr key={bundle._id}>
                <Td>{bundle.name}</Td>
                <Td maxWidth="160px">
                  {bundle.url ? (
                    <CopyButton url={bundle.url} />
                  ) : (
                    'Awaiting Confirmation'
                  )}
                </Td>
                <Td>
                  {bundle.total_price_cents
                    ? '$' + bundle.total_price_cents / 100
                    : 'Awaiting Confirmation'}
                </Td>
                <Td>
                  {hasUserJoinedBundle(bundle) ? (
                    '$' +
                    bundle.prices.find(price => price.email === user.email)
                      .price_cents /
                      100
                  ) : (
                    <JoinButton bundlecode={bundle.external_id} />
                  )}
                </Td>
                <Td>
                  {hasUserJoinedBundle(bundle) ? (
                    bundle.products
                      .find(product => product.email === user.email)
                      .products.join(', ')
                  ) : (
                    <JoinButton bundlecode={bundle.external_id} />
                  )}
                </Td>
                <Td>
                  {[
                    bundle.originator_email,
                    ...bundle.cobundlers.map(cobndlr => cobndlr.email),
                  ]
                    .filter(email => email !== user.email)
                    .join(', ')}
                </Td>
                <Td color={bundle.ready ? '#66b64a' : '#cc9d00'}>
                  {bundle.ready ? 'Confirmed' : 'Awaiting Confirmation'}
                </Td>
                <Td>{bundle.active_sub_count}</Td>
                <Td>${bundle.mrr_in_cents / 100}</Td>
                <Td>${bundle.lifetime_earnings / 100}</Td>
              </Tr>
            ))
          : null}
      </Tbody>
    </Table>
  );

  const dashboard = (
    <Flex width="full" align="center" justifyContent="center" mt={24}>
      <Box mx={5} maxWidth="1200px">
        <Box textAlign="left">
          <Heading as="h2" size="xl">
            Your Bundle Dashboard
          </Heading>
        </Box>
        <Box my={4} textAlign="left">
          <Text color="gray.400">
            All of your Bundles are in the table below. Once they are approved
            you can begin sharing your unique link with customers. If your
            Bundle is "Awaiting Confirmation" it means one or more of your
            co-bundlers have not set up and confirmed their account.
          </Text>
        </Box>
        <Box my={8} textAlign="left">
          <Heading as="h4" size="md">
            Earnings
          </Heading>
          <Button
            mt={2}
            color="gray.400"
            variant="link"
            textDecoration="underline"
            onClick={() => {
              setLoading(true);
              stripeExpressDashboardLink();
            }}
          >
            View your detailed earnings report in Stripe
            <ExternalLinkIcon ml={2} color="gray.700" />
          </Button>
        </Box>
        <Box my={8} textAlign="left">
          <Heading as="h4" size="md">
            Your Bundles
          </Heading>
          {bundlesTable}
        </Box>
        <Box my={6} textAlign="center">
          <Button mt={6} onClick={() => history.push('/create')}>
            + Bundle
          </Button>
        </Box>
      </Box>
    </Flex>
  );

  return loading ? (
    <Spinner />
  ) : data.bundles.length ? (
    dashboard
  ) : (
    createYourFirst
  );
};

export default Bundles;
